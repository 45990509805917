import * as React from 'react';
import type { Maybe, Settings } from '@types/gatsby-types';
import { graphql, ScriptStrategy, useStaticQuery } from 'gatsby';
import Script from 'analytics/components/Script';

const query = graphql`
    query AccessiBeQuery {
        settings {
            accessibe {
                enabled
            }
        }
    }
`;

interface AccessiBeQuery {
    settings: Maybe<Pick<Settings, 'accessibe'>>;
}

export default function AccessiBe() {
    const { settings } = useStaticQuery<AccessiBeQuery>(query);

    const accessibeEnabled = settings?.accessibe?.enabled;

    if (accessibeEnabled) {
        return (
            <Script
                strategy={ScriptStrategy.idle}
                cookieType="necessary"
                id="accessibe"
                content={`(function () {
    const s = document.createElement('script');
    const e = !document.body ? document.querySelector('head') : document.body;
    s.src = 'https://acsbapp.com/apps/app/assets/js/acsb.js';
    s.async = s.defer = true;
    s.onload = function () {
        acsbJS.init({
            statementLink: '',
            feedbackLink: '',
            footerHtml: '',
            hideMobile: false,
            hideTrigger: true,
            language: 'en',
            position: 'left',
            leadColor: '#11355a',
            triggerColor: '#11355a',
            triggerRadius: '50%',
            triggerPositionX: 'left',
            triggerPositionY: 'bottom',
            triggerIcon: 'default',
            triggerSize: 'medium',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            mobile: {
                triggerSize: 'medium',
                triggerPositionX: 'left',
                triggerPositionY: 'bottom',
                triggerOffsetX: 0,
                triggerOffsetY: 0,
                triggerRadius: '0',
            },
        });
    };
    e.appendChild(s);
})();
`}
            />
        );
    }

    return null;
}
