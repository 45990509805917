import * as React from 'react';
import type { Maybe, AdaChatBotSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery, ScriptStrategy } from 'gatsby';
import Script from 'analytics/components/Script';
import { useLocation } from '@reach/router';
import useAdaChatbotControls from 'core/hooks/useAdaChatbotControls';

export const adaSettingsQuery = graphql`
    query AdaChatBotQuery {
        settings {
            adaChatBot {
                enabled
                handle
                testMode
                nurseVisitRescheduleCampaignName
            }
        }
    }
`;

export type AdaChatBotQuery = {
    settings: Maybe<{
        adaChatBot: Maybe<AdaChatBotSettings>;
    }>;
};

type AdaChatBotProps = {
    isBookingPage: boolean;
    isCMS: boolean;
};

export default function AdaChatBot({ isBookingPage, isCMS }: AdaChatBotProps) {
    const { settings } = useStaticQuery<AdaChatBotQuery>(adaSettingsQuery);
    const botSettings = React.useMemo<AdaChatBotSettings>(
        () =>
            settings?.adaChatBot ?? {
                enabled: false,
            },
        [settings?.adaChatBot],
    );
    const showBot = Boolean(botSettings.enabled && !isCMS);
    const handle = botSettings.handle;
    const testMode = botSettings.testMode;

    React.useEffect(() => {
        if (isBookingPage) {
            document.documentElement.classList.add('hide-ada');
        } else {
            document.documentElement.classList.remove('hide-ada');
        }
    }, [isBookingPage]);

    useAdaChatbotControls(showBot && !isBookingPage, botSettings);

    // If we are auto-popping the chat window based on the URL param,
    // we should skip the delayed load
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const autoOpen = params.get('chat') !== null;

    if (!showBot || !handle) {
        return null;
    }

    return (
        <>
            <Script
                strategy={ScriptStrategy.idle}
                cookieType="necessary"
                timeoutMs={0}
                content={`window.adaSettings = window.adaSettings || {};
                 window.adaSettings.testMode = ${testMode};
                 window.adaSettings.handle = "${handle}";
`}
            />
            <Script
                strategy={ScriptStrategy.idle}
                cookieType="necessary"
                timeoutMs={autoOpen ? 100 : 5000}
                id="__ada"
                src="https://static.ada.support/embed2.js"
            />
        </>
    );
}
